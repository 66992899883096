import { useMemo, useState } from "react";

import { IPageInfo } from 'types/requestTypes';

export const usePagination = () => {
    const [pagination, setPagination] = useState<IPageInfo & { total: number; current: number }>({
        pageNumber: 1,
        pageSize: 20,
        totalItems: 0,
        totalPages: 0,
        total: 0,
        current: 0,
    });

    return useMemo(() => {

        return {
            pagination,
            setPagination: (pageInfo: IPageInfo) => {
                setPagination({
                    ...pageInfo,
                    total: pageInfo.totalItems,
                    current: pageInfo.pageNumber
                })
            }
        };
    }, [pagination])
}