import React from 'react';
import { Card as CardAntd, Typography } from 'antd';

import './card.scss';

type Props = {
  title: string;
  subtitle: string;
  value: any;
};

const { Title, Text } = Typography;

export const Card: React.FC<Props> = ({ title, subtitle, value }) => {
  return (
    <CardAntd className='card'>
      <Title className='value'>{value}</Title>
      <Title className='title' level={4}>{title}</Title>
      <Text className='subtitle'>{subtitle}</Text>
    </CardAntd>
  )
};