import { Rule } from "antd/es/form";

export type Field = {
  name: string;
  label: string;
  placeholder?: string;
  rules?: Rule[];
  
};

export const newClientFields: Field[] = [
  {
    name: 'name',
    label: 'Наименование клиента (фонда)',
    placeholder: 'Введите данные',
    rules: [
      { required: true, message: 'Это обязательное поле' },
      { max: 50, message: 'Максимальная длина 50 символов' },
    ],
  },
  {
    name: 'firstName',
    label: 'Имя',
    placeholder: 'Иван',
    rules: [
      { required: true, message: 'Это обязательное поле' },
      { max: 20, message: 'Максимальная длина 20 символов' }
    ]
  },
  {
    name: 'lastName',
    label: 'Фамилия',
    placeholder: 'Иванов',
    rules: [
      { required: true, message: 'Это обязательное поле' },
      { max: 20, message: 'Максимальная длина 20 символов' }
    ]
  },
  {
    name: 'middleName',
    label: 'Отчество',
    placeholder: 'Иванович',
    rules: [
      { required: true, message: 'Это обязательное поле' },
      { max: 20, message: 'Максимальная длина 20 символов' }
    ]
  },
  {
    name: 'vat',
    label: 'ИНН',
    placeholder: '012345678915',
    rules: [
      { required: true, message: 'Это обязательное поле' },
      { max: 15, message: 'Максимальная длина 15 символов' },
      { min: 10, message: 'Минимальная длина 10 символов' },
    ]
  },
  {
    name: 'email',
    label: 'Электронная почта',
    placeholder: 'Ivan@example.ru',
    rules: [
      { required: false },
      { max: 120, message: 'Максимальная длина 120 символов' }
    ]
  },
  {
    name: 'phone',
    label: 'Телефон',
    placeholder: '+7-900-000-00-00',
    rules: [
      { required: true, message: 'Это обязательное поле' },
      { max: 20, message: 'Максимальная длина 20 символов' }
    ]
  },
];
