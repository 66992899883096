import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";

import { fetchUserData } from "utils/requests";
import { clearUserData, setUserData } from "store/authSlice";
import { EClientRoute } from "types/clientRoutes.enum";

import useApi from "./useApi";

export const useAuth = () => {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    useApi(fetchUserData, {
        callOnMount: true,
        onSuccess: (userData) => {
            queueMicrotask(() => dispatcher(setUserData(userData)));
        },
        onError: (err) => {
            dispatcher(clearUserData());
            console.error(err);
            navigate(EClientRoute.Root);
        }
    });
}