import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Input, Row, Typography, Table, notification } from 'antd';
import Icon from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

import { Loader } from 'components/atoms';

import { getPartnerClients, getPartnerInfo, getPartnerStats } from 'utils/requests';
import { formatDate } from 'utils/formatDate';
import { ruCapitalTitle } from 'utils/antd.table';

import { IClientListData } from 'types/requestTypes';
import { EClientRoute } from 'types/clientRoutes.enum';
import { IProfileData } from 'types/profileData.interface';
import { IPartnerStats } from 'types/partnerStats.interface';
import { ServerResponseStatus } from 'types/serverResponseStatus.enum';

import { Card } from './components';

import { arrowNarrowCircleBroken, search, plus } from 'icons';

import './backOffice.page.scss';

const { Title, Text } = Typography;

const getColumns = (actions: { onDetailClick: (item: IClientListData) => void }): ColumnsType<IClientListData> => {
  return [
    {
      title: (props) => ruCapitalTitle(props, 'Клиент'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: '35%',
    },
    {
      title: (props) => ruCapitalTitle(props, 'Статус'),
      dataIndex: 'statusName',
      key: 'statusName',
      sorter: (a, b) => a.statusName?.localeCompare(b.statusName || '') ?? 1,
      render: (statusName) => {
        if (!statusName) return statusName;

        const statusClassName = statusName === 'Выплачено' ? 'success' : 'danger';
        return <Text className={statusClassName}>{statusName}</Text>;
      },
    },
    {
      title: (props) => ruCapitalTitle(props, 'Сумма выплаченная по сделке, ₽'),
      dataIndex: 'partnerAmountPaid',
      key: 'partnerAmountPaid',
      sorter: (a, b) => a.partnerAmountPaid - b.partnerAmountPaid,
    },
    {
      title: (props) => ruCapitalTitle(props, 'Сумма к выплате по сделке, ₽'),
      dataIndex: 'partnerAmountAccrued',
      key: 'partnerAmountAccrued',
      sorter: (a, b) => a.partnerAmountAccrued - b.partnerAmountPaid,
    },
    {
      title: 'Действия',
      dataIndex: '',
      key: 'action',
      render: (data) => (
        <Button type='link' onClick={() => actions.onDetailClick(data)}>
          Детализация
          <Icon component={arrowNarrowCircleBroken} />
        </Button>
      ),
    },
  ];
};

export const BackOfficePage: React.FC = () => {
  const navigate = useNavigate();
  const [clientData, setClientData] = useState<IClientListData[]>();
  const [profileData, setProfileData] = useState<IProfileData | null>(null);
  const [partnerStats, setPartnerStats] = useState<IPartnerStats | null>(null);

  useEffect(() => {
    const initial = async () => {
      try {
        setClientData([]);
        const partnerInfo = await getPartnerInfo();
        setProfileData(partnerInfo);

        const partnerStats = await getPartnerStats(String(partnerInfo.id));
        setPartnerStats(partnerStats);

        const partnerClients = await getPartnerClients({ PartnerId: partnerInfo.id });
        if (partnerClients) {
          setClientData(partnerClients.items);
        }
      } catch (e) {
        if (e === ServerResponseStatus.ForbiddenError) {
          navigate(`${EClientRoute.Root}/true`);
          return;
        }

        console.warn(e);
        notification.error({ message: 'Произошла ошибка при загрузке данных' });
      }
    };

    if (!clientData) {
      initial();
    }
  }, [clientData, navigate, profileData]);

  const columns = useMemo(() => {
    return getColumns({
      onDetailClick: (item: IClientListData) => {
        navigate(`${EClientRoute.Backoffice}/client/${item.id}`);
      },
    });
  }, [navigate]);

  return (
    <>
      {partnerStats ? (
        <Row gutter={[20, 16]} style={{ width: '100%' }}>
          <Col xs={24} md={8} lg={8} className='col-card'>
            <Card value={partnerStats?.clientsCount || 0} title='Привлеченные клиенты' subtitle='(всего)' />
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} className='col-card'>
            <Card value={`${partnerStats?.partnerAmountPaid || 0} ₽`} title='Выплаты' subtitle='(всего за период)' />
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} className='col-card'>
            <Card value={`${partnerStats?.partnerAmountAccrued || 0} ₽`} title='Сумма к выплате' subtitle='(за следующий период)' />
          </Col>
        </Row>
      ) : (
        <Loader />
      )}

      <div className='table-header'>
        <div className='text'>
          <Title level={4}>Клиенты</Title>
        </div>
        <div className='search'>
          <Input
            placeholder='Введите данные'
            suffix={<Icon component={search} />}
            onChange={async (e) => {
              if (!profileData) return;
              const partnerClients = await getPartnerClients({ PartnerId: profileData.id, Search: e.target.value });
              if (partnerClients) {
                setClientData(partnerClients.items);
              }
            }}
          />
        </div>
        <div className='action'>
          <Button type='primary' icon={<Icon component={plus} />} onClick={() => navigate(EClientRoute.BackofficeNewClient)}>
            Добавить клиента
          </Button>
        </div>
      </div>
      <Table
        className='backoffice-table'
        columns={columns}
        dataSource={clientData}
        bordered
        scroll={{ x: 'max-content' }}
        pagination={false}
        onRow={(itemRow: IClientListData) => {
          return {
            onClick: () => {
              navigate(`${EClientRoute.Backoffice}/client/${itemRow.id}`);
            },
          };
        }}
      />
      {partnerStats?.lastSyncDate && (
        <div className='crm-stats-sync'>Таблица составлена на основе данных CRM-системы {formatDate(partnerStats.lastSyncDate)}</div>
      )}
    </>
  );
};
