import { useNavigate, useParams } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { Typography, notification, Table } from 'antd';
import Icon from '@ant-design/icons';

import { ColumnsType, TablePaginationConfig } from 'antd/es/table';

import useApi from 'hooks/useApi';
import { usePagination } from 'hooks/usePagination';

import { chevronLeft } from 'icons';

import { getClientProjectTrade, getPartnerInfo } from 'utils/requests';
import { ruCapitalTitle } from 'utils/antd.table';

import { ITradeInfoData, TradeDetailsArgs } from 'types/requestTypes';

import { LoadingPage } from '../../components/pages/LoadingPage';

import './backOfficeTradeDetail.page.scss';

const { Title, Text } = Typography;

const columns: ColumnsType<ITradeInfoData> = [
  {
    title: (props) => ruCapitalTitle(props, 'Дата сделки'),
    dataIndex: 'approvalDate',
    key: 'approvalDate',
    sorter: (a, b) => new Date(a.approvalDate).getTime() - new Date(b.approvalDate).getTime(),
    render: (approvalDate) => {
      const date = new Date(approvalDate);
      const month = String(date.getMonth() + 1);
      const monthText = month.length === 1 ? `0${month}` : month;

      const dateText = `${date.getDate()}.${monthText}.${date.getFullYear()}`;

      return <Text className='fw-500'>{dateText}</Text>;
    },
  },
  {
    title: (props) => ruCapitalTitle(props, 'Статус'),
    dataIndex: 'statusName',
    key: 'statusName',
    sorter: (a, b) => a.statusName?.localeCompare(b.statusName || '') ?? 1,
    render: (statusName) => {
      if (!statusName) return statusName;

      return <Text className='main fw-500'>{statusName}</Text>;
    },
  },
  {
    title: (props) => ruCapitalTitle(props, 'Сумма выплаченная по сделке, ₽'),
    dataIndex: 'partnerAmountPaid',
    key: 'partnerAmountPaid',
    sorter: (a, b) => a.partnerAmountPaid - b.partnerAmountPaid,
  },
  {
    title: (props) => ruCapitalTitle(props, 'Сумма к выплате по сделке, ₽'),
    dataIndex: 'partnerAmountAccrued',
    key: 'partnerAmountAccrued',
    sorter: (a, b) => a.partnerAmountAccrued - b.partnerAmountAccrued,
  },
];

const BackOfficeTradeDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { tradeId, tradeName: encodedTradeName } = useParams();
  const tradeName = useMemo(() => decodeURIComponent(encodedTradeName || ''), [encodedTradeName]);
  const [projectTrade, setProjectTrade] = useState<ITradeInfoData[]>();
  const { pagination, setPagination } = usePagination();

  const initialCallArgs: TradeDetailsArgs = { projectId: tradeId ?? '', pageNumber: 1 };

  const { data: profileData } = useApi(getPartnerInfo, {
    callOnMount: true,
    onError: () => notification.error({ message: 'Произошла ошибка при загрузке данных' }),
  });

  const { isLoading, load: loadClientProjectTrade } = useApi(getClientProjectTrade, {
    callOnMount: true,
    initialCallArgs,
    onSuccess: (data) => {
      if (!data) return;

      setProjectTrade(data.items);
      setPagination(data.pageInfo);
    },
    onError: () => notification.error({ message: 'Произошла ошибка при загрузке данных' }),
  });

  const onTableChange = (pagination: TablePaginationConfig) => {
    loadClientProjectTrade({
      projectId: tradeId ?? '',
      pageNumber: pagination.current,
    });
  };

  if (!profileData && !projectTrade) {
    return <LoadingPage />;
  }

  return (
    <>
      <Title level={3} onClick={() => navigate(-1)} className='detail-header'>
        <Icon className='chevron-icon' component={chevronLeft} />
        Детализация по сделке: {tradeName}
      </Title>
      <Table
        className='backoffice-trade-detail-table'
        scroll={{ x: 'max-content' }}
        columns={columns}
        dataSource={projectTrade}
        pagination={pagination}
        loading={isLoading}
        onChange={onTableChange}
      />
    </>
  );
};

export default BackOfficeTradeDetailPage;
