import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'

interface State<T> {
    data?: T;
    error?: Error;
    isLoading: boolean;
}

type Action<T, U> =
    | { type: 'load', args?: U }
    | { type: 'fetched'; payload: T }
    | { type: 'error'; payload: Error }

type ApiCallOptions<TResult, TArgs> = {
    callOnMount?: boolean;
    initialCallArgs?: TArgs;
    onSuccess?: (data: TResult) => void,
    onError?: (error: Error) => void,
}

const initialState = {
    error: undefined,
    data: undefined,
    isLoading: false,
}

function useApi<TResult, TCallArgs>(
    method: (args: TCallArgs) => Promise<TResult>,
    options?: ApiCallOptions<TResult, TCallArgs>) {
    const apiMethodRef = useRef(method);
    const initialCallWasDone = useRef<boolean>(false);

    const fetchReducer = (state: State<TResult>, action: Action<TResult, TCallArgs>): State<TResult> => {
        switch (action.type) {
            case 'load':
                return { ...initialState, isLoading: true, }
            case 'fetched':
                let newState = { ...initialState, data: action.payload, isLoading: false }
                options?.onSuccess && options.onSuccess(action.payload)
                return newState;
            case 'error':
                const newErrorState = { ...initialState, error: action.payload, isLoading: false }
                options?.onError && options.onError(action.payload);
                return newErrorState;
            default:
                return state
        }
    };

    const [state, dispatch] = useReducer(fetchReducer, initialState)

    const makeApiCall = useCallback((callArgs: TCallArgs): Promise<TResult> => {
        dispatch({ type: 'load', args: callArgs });
        return apiMethodRef
            .current(callArgs)
            .then(response => {
                dispatch({ type: 'fetched', payload: response });
                return response;
            })
            .catch(error => {
                dispatch({ type: 'error', payload: error });
                throw error;
            })
    }, [dispatch]);

    useEffect(() => {
        if (!initialCallWasDone.current && options?.callOnMount) {
            initialCallWasDone.current = true;
            makeApiCall(options?.initialCallArgs as TCallArgs);
        }
    }, [makeApiCall, options?.callOnMount, options?.initialCallArgs])

    return useMemo(() => {
        return {
            load: makeApiCall,
            data: state.data,
            error: state.data,
            isLoading: state.isLoading,
        }
    }, [makeApiCall, state.data, state.isLoading]);
}

export default useApi
