import { Row } from 'antd';

import cx from 'classnames';

import './infoBlock.scss';

type InfoBlockType = {
  children: React.ReactNode;
  className?: string;
};

export const InfoBlock: React.FC<InfoBlockType> = ({ children, className }: InfoBlockType) => {
  return <Row className={cx('info-block', className)}>{children}</Row>;
};
