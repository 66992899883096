import React from 'react';
import { Link } from 'react-router-dom';
import { EClientRoute } from 'types/clientRoutes.enum';
import cx from 'classnames';

import back from 'icons/back.svg';

import './ReturnLink.scss';

type IReturnLinkProps = {
  route: EClientRoute | string;
  linkText?: string;
  position?: 'top-left';
  onClick?: () => boolean;
};

export const ReturnLink: React.FC<IReturnLinkProps> = ({ route, linkText, position, onClick }) => {
  return (
    <div className={cx('return-link-container', position === 'top-left' && 'top-left')}>
      <span className='return-link'>
        <img src={back} alt='Вернуться назад' />
        <Link
          onClick={(e) => {
            if (onClick && !onClick()) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          to={route}
        >
          {linkText || 'Вернуться назад'}
        </Link>
      </span>
    </div>
  );
};
