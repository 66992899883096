import React, { ReactEventHandler } from 'react';

import cx from "classnames";

import filetypedoc from 'icons/filetypedoc.svg';
import filetypejpg from 'icons/filetypejpg.svg';
import filetypepdf from 'icons/filetypepdf.svg';
import filetypeppt from 'icons/filetypeppt.svg';
import filetypesvg from 'icons/filetypesvg.svg';
import filetypetxt from 'icons/filetypetxt.svg';
import filetypexls from 'icons/filetypexls.svg';
import filetypezip from 'icons/filetypezip.svg';
import filetypedocx from 'icons/filetypedocx.svg';
import filetypepptx from 'icons/filetypepptx.svg';
import filetypexlsx from 'icons/filetypexlsx.svg';

import './DocumentToDownload.scss';

const fileIcons: Record<string, string> = {
  doc: filetypedoc,
  jpg: filetypejpg,
  pdf: filetypepdf,
  ppt: filetypeppt,
  svg: filetypesvg,
  txt: filetypetxt,
  xls: filetypexls,
  zip: filetypezip,
  docx: filetypedocx,
  pptx: filetypepptx,
  xlsx: filetypexlsx,
};

type FileElement = {
  name: string;
  size: number;
};

export interface IDocumentToDownloadProps {
  file: FileElement;
  icon: string;
  clickable?: boolean;
  onIconClick?: ReactEventHandler;
  onContainerClick?: ReactEventHandler;
}

export const DocumentToDownload: React.FC<IDocumentToDownloadProps> = ({ file, icon, onIconClick, onContainerClick, clickable = true }) => {
  return (
    <div className={cx('file-chosen', !clickable && 'not-clickable')} onClick={onContainerClick}>
      <img src={fileIcons[file.name.split('.').pop() || 'txt'] || ''} alt={file.name} />
      <div className='file-chosen-info'>
        <span className='file-chosen-name'>{file.name}</span>
        {file.size ? <span className='file-chosen-size'>{(file.size / Math.pow(1024, 2)).toFixed(1)} M6</span> : null}
      </div>
      {icon && <img alt='clear' src={icon} onClick={onIconClick} style={{ cursor: 'pointer' }} />}
    </div>
  );
};
