import React, { memo } from 'react';
import { Space, Typography } from 'antd';

export const NotFoundPage: React.FC = memo(() => {
  return (
    <Space direction='vertical' align='center' className='full-width full-height flex-center'>
      <Typography.Title level={3}>Страница не найдена</Typography.Title>
    </Space>
  );
});
