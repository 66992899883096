import { ColumnTitleProps } from 'antd/es/table/interface';

import sortIcon from 'icons/chevron-selector-vertical.svg';

export const ruCapitalTitle = (_: ColumnTitleProps<any>, titleName: string): React.ReactNode => {
  return (
    <div style={{ display: 'flex', justifyContent: 'spaceBetween' }}>
      {titleName}
      <img style={{ paddingLeft: '8px' }} alt='Кнопка сортировки' src={sortIcon} />
    </div>
  );
};
