import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';

import 'react-phone-number-input/style.css';

import { LoadingPage, NotFoundPage } from 'components/pages';
import { AppLayout } from 'components/layout/AppLayout/app.layout';
import { BackOfficePage, BackOfficeNewClient } from 'pages';

import { EClientRoute } from 'types/clientRoutes.enum';

import './App.scss';
import './styles/antd.scss';
import { ErrorBoundary } from 'components/shared';

const WelcomePage = lazy(() => import('./pages/WelcomePage/welcome.page'));
const PreRegisterPage = lazy(() => import('./pages/PreRegisterPage/preRegister.page'));
const ChooseLegalFormPage = lazy(() => import('./pages/Registration/chooseLegalForm.page'));
const PartnerFormPage = lazy(() => import('./pages/Registration/partnerForm.page'));
const RegisterNewPartnerPage = lazy(() => import('./pages/Registration/registerNewPartner.page'));
const PartnerFormSentPage = lazy(() => import('./pages/Registration/partnerFormSent.page'));
const SignAgreementPage = lazy(() => import('./pages/Registration/signAgreement.page'));
const AgreementSentPage = lazy(() => import('./pages/Registration/agreementSent.page'));
const BackOfficeProfilePage = lazy(() => import('./pages/BackOfficeProfile/backOfficeProfile.page'));
const BackOfficeDocumentsPage = lazy(() => import('./pages/BackOfficeDocuments/backOfficeDocuments.page'));
const BackOfficeTradeDetailPage = lazy(() => import('./pages/BackOfficeTradeDetailPage/backOfficeTradeDetail.page'));
const BackOfficeClientDetailPage = lazy(() => import('./pages/BackOfficeClientDetailPage/backOfficeClientDetail.page'));

const queryClient = new QueryClient();

export const App: React.FC = () => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimaryActive: '#3E4784',
            colorPrimaryText: '#fff',
            colorPrimary: '#4E5BA6',
            colorPrimaryHover: '#707ecb',
            colorTextDisabled: '#98A2B3',
            colorBgContainerDisabled: '#E4E7EC',
            colorBorder: '#4E5BA6',

            colorLink: '#98A2B3',
            colorLinkHover: '#98A2B3',
          },
          Typography: {
            sizeMarginHeadingVerticalEnd: 0,
            sizeMarginHeadingVerticalStart: 0,
          },
          Checkbox: {
            colorPrimary: '#4E5BA6',
          },
          Radio: {
            colorPrimary: '#4E5BA6',
          },
          Layout: {
            colorBgHeader: '#FFFFFF',
            colorBgBody: '#FFFFFF',
          },
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <div className='App'>
          <AppLayout>
            <Suspense fallback={<LoadingPage />}>
              <ErrorBoundary>
                <Routes>
                  <Route index path='/' element={<WelcomePage />} />
                  {/* registration */}
                  <Route path={EClientRoute.Register} element={<ChooseLegalFormPage />} />
                  <Route path={`${EClientRoute.Register}/:legalForm`} element={<PartnerFormPage />} />
                  <Route path={`${EClientRoute.RegisterSent}/:hideBack`} element={<PartnerFormSentPage />} />
                  <Route path={`${EClientRoute.RegisterSent}`} element={<PartnerFormSentPage />} />
                  <Route path={EClientRoute.RegisterNew} element={<RegisterNewPartnerPage />} />
                  <Route path={EClientRoute.Preregister} element={<PreRegisterPage />} />
                  <Route path={EClientRoute.Sign} element={<SignAgreementPage />} />
                  <Route path={EClientRoute.AgreementSent} element={<AgreementSentPage />} />
                  {/* backoffice */}
                  <Route path={EClientRoute.BackofficeNewClient} element={<BackOfficeNewClient />} />
                  <Route path={`${EClientRoute.Backoffice}/profile`} element={<BackOfficeProfilePage />} />
                  <Route path={`${EClientRoute.Backoffice}/docs`} element={<BackOfficeDocumentsPage />} />
                  <Route path={`${EClientRoute.Backoffice}/client/:clientId`} element={<BackOfficeClientDetailPage />} />
                  <Route path={EClientRoute.Backoffice} element={<BackOfficePage />} />
                  <Route
                    path={`${EClientRoute.Backoffice}/trade/:tradeId/:tradeName`}
                    element={<BackOfficeTradeDetailPage />}
                  />
                  <Route path='*' element={<NotFoundPage />} />
                </Routes>
              </ErrorBoundary>
            </Suspense>
          </AppLayout>
        </div>
      </QueryClientProvider>
    </ConfigProvider>
  );
};
