import React, { useEffect, useState } from 'react';
import { Space, Typography } from 'antd';

import './LoadingPage.scss';

export interface ILoadingPageProps {
  timeoutMs?: number;
}

export const LoadingPage: React.FC<ILoadingPageProps> = ({ timeoutMs }) => {
  const [isTimeoutExpired, setIsTimeoutExpired] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsTimeoutExpired(true), timeoutMs || 300);
  }, [timeoutMs]);

  return isTimeoutExpired ? (
    <Space direction='vertical' align='center' className='full-width full-height flex-center'>
      <Typography.Title level={3}>Идёт загрузка...</Typography.Title>
      <span className='loader' />
    </Space>
  ) : null;
};
