export class ValidationError extends Error { }

// TODO: remove any
export const fieldValidator: Record<string, any> = {
  vat(v: string) {
    if (v.length < 10 || v.length > 15) {
      throw new ValidationError('Неверный формат ИНН');
    }

    if (isNaN(+v)) {
      throw new ValidationError('Поле ИНН может содержать только цифры');
    }

    return true;
  },
  phone(p: string) {
    if (p.length < 10 || p.length > 15) {
      throw new ValidationError('Не удалось распознать номер телефона');
    }

    if (isNaN(+p)) {
      throw new ValidationError('Поле Телефона может содержать только цифры');
    }

    return true;
  },
  default() {
    return true;
  }
};
