import React from 'react';
import { IClientInfo } from 'types/requestTypes';

import './clientDetails.scss';

type Prop = {
  data: IClientInfo;
};

export const ClientDetails: React.FC<Prop> = ({ data }) => {
  return (
    <div className='client-details'>
        <div className='client-info-item fond'>
          <div className='client-info-label'>Наименование клиента (фонда):</div>
          <div className='client-info-value'>{data.name}</div>
        </div>
        <div className='client-info-item fio'>
          <div className='client-info-label'>ФИО:</div>
          <div className='client-info-value'>{data.lastName} {data.firstName} {data.middleName}</div>
        </div>
        <div className='client-info-item phone'>
          <div className='client-info-label'>Телефон:</div>
          <div className='client-info-value'>{data.phone}</div>
        </div>
        <div className='client-info-item email'>
          <div className='client-info-label'>E-mail:</div>
          <div className='client-info-value'>{data.email}</div>
        </div>
        <div className='client-info-item inn'>
          <div className='client-info-label'>ИНН:</div>
          <div className='client-info-value'>{data.vat}</div>
        </div>
    </div>
  );
}
