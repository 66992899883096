export enum EClientRoute {
  Root = '/',
  Register = '/register',
  Preregister = '/preregister',
  Sign = '/sign',
  RegisterNew = '/register/new',
  RegisterSent = '/register/sent',
  Backoffice = '/backoffice',
  BackofficeNewClient = '/backoffice/client/new',
  AgreementSent = '/sign/sent',
}
