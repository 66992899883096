import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row, Layout, Typography, Menu, MenuProps, Button, Space, Drawer, Divider } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { EClientRoute } from 'types/clientRoutes.enum';

import './backOffice.layout.scss';

import logo from 'icons/logo.svg';
import closeIcon from 'icons/closeblack.svg';
import menuIcon from 'icons/menu.svg';
import exit from 'icons/door-open.svg';

import { UserMenuItem } from 'components/shared/UserMenuItem';

import { clearUserData } from 'store/authSlice';
import { useSelectState } from 'hooks/useStore';
import { useValidateUser } from 'hooks/useValidateUser';

const { Text } = Typography;
const { Header, Footer, Content } = Layout;

type BackOfficePageTemplateProps = {
  children?: React.ReactNode;
};

const getMenuItems = (canNavigate: boolean): MenuProps['items'] => {
  if (canNavigate) {
    return [
      {
        label: <Link to={EClientRoute.Backoffice}>Главная</Link>,
        key: 'main',
      },
      {
        label: <Link to={`${EClientRoute.Backoffice}/profile`}>Профиль</Link>,
        key: 'profile',
      },
      {
        label: <Link to={`${EClientRoute.Backoffice}/docs`}>Документы</Link>,
        key: 'docs',
      },
    ];
  }

  return [];
};

const onContactWithUsClicked = (e: { preventDefault: () => void }) => {
  e.preventDefault();
  window.open('mailto:agent@ruskap.com', '_blank');
};

export const BackOfficeLayout: React.FC<BackOfficePageTemplateProps> = ({ children }) => {
  useValidateUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelectState((store) => store.auth.user);

  const [open, setOpen] = useState(false);
  const menuItems = useMemo(() => {
    return getMenuItems((userData?.roles?.length || 0) > 0);
  }, [userData]);

  const quitCb = useCallback(() => {
    sessionStorage.clear();
    dispatch(clearUserData());
    navigate(EClientRoute.Root);
  }, [dispatch, navigate]);
  return (
    <Layout>
      <Header>
        <Link to={EClientRoute.Backoffice} className='header-logo'>
          <img src={logo} alt='logo' />
        </Link>
        <Col flex='1 1 auto' xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
          <Menu mode='horizontal' className='ant-header-menu' items={menuItems} />
        </Col>
        <Col>
          <Space size={32} className='full-height'>
            <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
              <Button type='primary' onClick={onContactWithUsClicked}>
                Написать на почту
              </Button>
            </Col>
            <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
              <UserMenuItem />
            </Col>
            <Col className='burger-button'>
              <img src={menuIcon} className='pointer' alt='Иконка меню' onClick={() => setOpen(true)} />
            </Col>
          </Space>
        </Col>
      </Header>
      <Content className='back-office-content'>{children}</Content>
      <Footer>
        <Row className='full-width'>
          <Col xs={24} md={8}>
            <Text>Все права защищены</Text>
          </Col>
          <Col xs={24} md={7} className='text-center sm-text-left'>
            <a href='tel:+7 812 708 08 95'>+7 812 708 08 95</a>
          </Col>
          <Col sm={24} md={9} className='text-right sm-text-left hide-xs'>
            <Row gutter={6}>
              <Col xs={24} sm={24} flex={'1 1 auto'}>
                <a href='mailto:info@ruskap.com' target='_blank' rel='noreferrer'>
                  info@ruskap.com
                </a>
              </Col>
              <Col xs={24} sm={24} flex={'2 1 auto'}>
                <Text>г. Санкт-Петербург, ул. Шпалерная, д.54, лит В</Text>
              </Col>
            </Row>
          </Col>
          <Col xs={24} className='visible-xs'>
            <a href='mailto:info@ruskap.com' target='_blank' rel='noreferrer'>
              info@ruskap.com
            </a>
          </Col>
          <Col xs={24} className='visible-xs'>
            <Text>г. Санкт-Петербург, ул. Шпалерная, д.54, лит В</Text>
          </Col>
        </Row>
      </Footer>
      <Drawer
        extra={<img className='pointer' src={closeIcon} alt='Закрыть меню' onClick={() => setOpen(false)} />}
        placement='right'
        onClose={() => setOpen(false)}
        open={open}
      >
        <img src={logo} alt='Лого' />
        <Menu mode='vertical' items={menuItems} />
        <Button type='primary' onClick={onContactWithUsClicked}>
          Написать на почту
        </Button>
        <Divider />
        <Button type='ghost' onClick={quitCb}>
          <Row gutter={12} align={'middle'}>
            <img src={exit} alt='Выход' />
            <Col>Выход</Col>
          </Row>
        </Button>
      </Drawer>
    </Layout>
  );
};
