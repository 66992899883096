import React from 'react';

import { ErrorPage } from 'components/pages';

type Props = { children: React.ReactElement };

export class ErrorBoundary extends React.Component<Props, { error: string }> {
  constructor(props: Props) {
    super(props);
    this.state = { error: '' };
  }

  componentDidCatch(error: Error) {
    this.setState({ error: `${error.name}: ${error.message}` });
  }

  render() {
    const { error } = this.state;
    if (error) {
      return <ErrorPage errorMessage={error} classNames='full-width full-height flex-center' />;
    } else {
      return <>{this.props.children}</>;
    }
  }
}
