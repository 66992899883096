import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography, Table, notification } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import Icon from '@ant-design/icons';

import { Loader } from 'components/atoms';

import { usePagination } from 'hooks/usePagination';
import useApi from 'hooks/useApi';

import { arrowNarrowCircleBroken, chevronLeft } from 'icons';

import { getClientDetails, getClientProjects, getPartnerInfo } from 'utils/requests';
import { ruCapitalTitle } from 'utils/antd.table';

import { EClientRoute } from 'types/clientRoutes.enum';
import { IProfileData } from 'types/profileData.interface';
import { IClientInfo, IClientProjectData, ClientProjectsArgs } from 'types/requestTypes';
import { ServerResponseStatus } from 'types/serverResponseStatus.enum';

import { LoadingPage } from '../../components/pages/LoadingPage';
import { ClientDetails } from './components';

import './backOfficeClientDetail.page.scss';

const { Title, Text } = Typography;

const getColumns = (actions: { onDetailClick: (item: IClientProjectData) => void }): ColumnsType<IClientProjectData> => {
  return [
    {
      title: (props) => ruCapitalTitle(props, 'Наименование сделки'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: '25%',
    },
    {
      title: (props) => ruCapitalTitle(props, 'Статус'),
      dataIndex: 'statusName',
      key: 'statusName',
      sorter: (a, b) => a.statusName?.localeCompare(b.statusName || '') ?? 1,
      render: (statusName) => {
        if (!statusName) return statusName;

        const statusSuccessClassName = statusName === 'Выплачено' ? 'success' : null;
        const statusDangerClassName = statusName === 'Невыплачено' ? 'danger' : null;
        const statusClassName = (statusSuccessClassName || statusDangerClassName) ?? 'main';

        return <Text className={statusClassName}>{statusName}</Text>;
      },
    },
    {
      title: (props) => ruCapitalTitle(props, 'Сумма выплаченная по сделке, ₽'),
      dataIndex: 'partnerAmountPaid',
      key: 'partnerAmountPaid',
      sorter: (a, b) => a.partnerAmountPaid - b.partnerAmountPaid,
    },
    {
      title: (props) => ruCapitalTitle(props, 'Сумма к выплате по сделке, ₽'),
      dataIndex: 'partnerAmountAccrued',
      key: 'partnerAmountAccrued',
      sorter: (a, b) => a.partnerAmountAccrued - b.partnerAmountPaid,
    },
    {
      title: 'Действия',
      dataIndex: '',
      key: 'action',
      render: (data) => (
        <Button type='link' onClick={() => actions.onDetailClick(data)}>
          Детализация
          <Icon component={arrowNarrowCircleBroken} />
        </Button>
      ),
    },
  ];
};

const BackOfficeClientDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [clientDetails, setClientDetails] = useState<IClientInfo>();
  const [clientProjects, setClientProjects] = useState<IClientProjectData[]>();
  const [profileData, setProfileData] = useState<IProfileData | null>(null);
  const [clientName, setClientName] = useState<string>();

  const { pagination, setPagination } = usePagination();

  const initialCallArgs: ClientProjectsArgs = { clientId: clientId ?? '', pageNumber: 1 };

  const { isLoading, load: loadClientProjects } = useApi(getClientProjects, {
    callOnMount: true,
    initialCallArgs,
    onSuccess: (data) => {
      setClientProjects(data.items);
      setPagination(data.pageInfo);
    },
    onError: () => notification.error({ message: 'Произошла ошибка при загрузке сделок' }),
  });

  useEffect(() => {
    const initial = async () => {
      try {
        const partnerInfo = await getPartnerInfo();
        setProfileData(partnerInfo);

        const clientDetails = await getClientDetails(clientId as string);
        setClientDetails(clientDetails);
      } catch (e) {
        if (e === ServerResponseStatus.ForbiddenError) {
          navigate(EClientRoute.Root);
          return;
        }
        console.warn(e);
        notification.error({ message: 'Произошла ошибка при загрузке данных' });
      }
    };
    initial();
  }, [clientId, navigate]);

  useEffect(() => {
    if (!clientDetails) return;

    const { firstName, lastName, middleName } = clientDetails;

    setClientName(`${lastName} ${firstName} ${middleName}`);
  }, [clientDetails]);

  const columns = useMemo(() => {
    return getColumns({
      onDetailClick: (tradeItem: IClientProjectData) => {
        // клик на кнопку
        navigate(`${EClientRoute.Backoffice}/trade/${tradeItem.id}/${encodeURIComponent(tradeItem.name)}`);
      },
    });
  }, [navigate]);

  const onTableChange = (pagination: TablePaginationConfig) => {
    loadClientProjects({
      clientId: clientId ?? '',
      pageNumber: pagination.current,
    });
  };

  if (!profileData && !clientDetails) {
    return <LoadingPage />;
  }

  return (
    <>
      <Title level={3} onClick={() => navigate(EClientRoute.Backoffice)} className='detail-header'>
        <Icon component={chevronLeft} className='chevron-icon' />
        Детализация по клиенту: {clientName}
      </Title>

      {clientDetails ? <ClientDetails data={clientDetails} /> : <Loader />}

      <Title level={3} className='detail-header'>
        Сделки с клиентом
      </Title>

      <Table
        className='backoffice-client-projects-table'
        scroll={{ x: 'max-content' }}
        columns={columns}
        dataSource={clientProjects}
        pagination={pagination}
        loading={isLoading}
        onRow={(itemRow: IClientProjectData) => {
          return {
            onClick: () => {
              // клик на строчку
              navigate(`${EClientRoute.Backoffice}/trade/${itemRow.id}/${encodeURIComponent(itemRow.name)}`);
            },
          };
        }}
        onChange={onTableChange}
      />
    </>
  );
};

export default BackOfficeClientDetailPage;
