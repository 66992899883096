import { apiClient } from 'api';

import { OTPData } from 'types/otpData.interface';
import { TPartnerForm } from 'types/partnerForms.interface';
import { ApiUrl } from 'types/apiUrl.enum';

import { IAddClientData } from 'types/addClientData.interface';
import { IProfileData } from 'types/profileData.interface';
import {
  IUserData,
  IGetPartnerClientsParams,
  IClientResponse,
  IClientInfo,
  IClientProjectResponse,
  ITradeInfoResponse,
  ClientProjectsArgs,
  TradeDetailsArgs,
} from 'types/requestTypes';

export const fetchUserData = async () => {
  try {
    const { data } = await apiClient.get<IUserData>(ApiUrl.ApiAuthGet);

    return data;
  } catch (error) {
    throw error;
  }
};

export const getCaptchaCode = async () => {
  try {
    const { data } = await apiClient.get(ApiUrl.ApiCaptchaCode);

    return data;
  } catch (error) {
    throw error;
  }
};

export const createLoginOTP = async (login: OTPData) => {
  try {
    const { data } = await apiClient.post(ApiUrl.ApiAuthLoginOTP, login);

    return data;
  } catch (error) {
    throw error;
  }
};

export const createNewOTP = async (login: OTPData) => {
  try {
    const { data } = await apiClient.post(ApiUrl.ApiAuthNewOTP, login);

    return data;
  } catch (error) {
    throw error;
  }
};

export const checkOTP = async ({ login, password }: { login: string; password: string }) => {
  try {
    const { data } = await apiClient.post(ApiUrl.ApiAuthPost, { login, password });

    return data;
  } catch (error) {
    throw error;
  }
};

export const registerPartner = async (request: TPartnerForm, validationCode: string, form: string) => {
  try {
    const { data } = await apiClient.post(`${ApiUrl.ApiPatnersPost}${form}`, { request, validationCode });

    return data;
  } catch (error) {
    throw error;
  }
};

export const getPartnerInfo = async () => {
  try {
    const { data } = await apiClient.get<IProfileData>(ApiUrl.ApiGetCurrentPatner);

    return data;
  } catch (error) {
    throw error;
  }
};

export const getPartnerStats = async (partnerId: string) => {
  try {
    const { data } = await apiClient.get(`${ApiUrl.ApiGetPatnerStats}${partnerId}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const getPartnerClients = async (params: IGetPartnerClientsParams) => {
  try {
    const qs = new URLSearchParams(params as Record<string, string>);
    const { data } = await apiClient.get<IClientResponse>(`${ApiUrl.ApiClientsGet}?${qs.toString()}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const addNewClient = async (params: IAddClientData) => {
  try {
    const { data } = await apiClient.post(ApiUrl.ApiClientsPost, params);

    return data;
  } catch (error) {
    throw error;
  }
};
export const uploadAgreement = async (partnerId: string, file: File) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', partnerId);

    const { data } = await apiClient.post(ApiUrl.ApiFilesPatnerGet, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const getPartnerDocuments = async (partnerId: string) => {
  try {
    const { data } = await apiClient.get<string[]>(`${ApiUrl.ApiFilesPatnerGet}/${partnerId}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const getClientDetails = async (clientId: string) => {
  try {
    const { data } = await apiClient.get<IClientInfo>(`${ApiUrl.ApiClientsGet}/${clientId}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const getClientProjects = async ({ clientId, pageNumber }: ClientProjectsArgs) => {
  try {
    const { data } = await apiClient.get<IClientProjectResponse>(
      `${ApiUrl.ApiClientProjectsGet}?ClientId=${clientId}&Page.Number=${pageNumber}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getClientProjectTrade = async ({ projectId, pageNumber }: TradeDetailsArgs) => {
  try {
    const { data } = await apiClient.get<ITradeInfoResponse>(
      `${ApiUrl.ApiClientProjectTradeGet}?ProjectId=${projectId}&Page.Number=${pageNumber}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getDocument = async (profileId: string, documentName: string) => {
  try {
    const { data } = await apiClient.get(`/files/partner/${profileId}/download?filename=${documentName}`, {
      responseType: 'blob',
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const getContract = async () => {
  try {
    const { data } = await apiClient.get('/files/partner/contract/download', {
      responseType: 'blob',
    });

    return data;
  } catch (error) {
    throw error;
  }
};
