import React from 'react';
import { Button, Space, Typography } from 'antd';

export type IErrorPageProps = {
  errorMessage?: string;
  classNames?: string;
};

export const ErrorPage: React.FC<IErrorPageProps> = ({ errorMessage, classNames }) => (
  <Space direction='vertical' align='center' className={classNames}>
    <Typography.Title level={3}>Ошибка!</Typography.Title>
    <span>{errorMessage}</span>
    <Button type='primary' block onClick={() => window.location.reload()}>
      Обновить страницу
    </Button>
  </Space>
);
