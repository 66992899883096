import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IUserData } from 'types/requestTypes';

interface SettingsState {
    isLoaded: boolean;
    isAuthenticated: boolean;
    user: IUserData | null;
}

const initialState: SettingsState = {
    user: null,
    isLoaded: false,
    isAuthenticated: false,
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<IUserData>) => {
            state.isAuthenticated = true;
            state.user = action.payload;
            state.isLoaded = true;
        },
        clearUserData: (state) => {
            state.isAuthenticated = false;
            state.user = null;
            state.isLoaded = true; 
        }
    },
})

export const { setUserData, clearUserData } = settingsSlice.actions

export default settingsSlice.reducer