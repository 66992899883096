export enum ServerResponseStatus {
  Ok = 'ok',
  NotFound = 'not found',
  ValidationError = 'validation error',
  ForbiddenError = "forbidden server error",
  AuthorizationError = 'authorization error',
  LimitExceededError = 'limit exceeded error',
  InternalServerError = 'internal server error',
  Conflict = 'conflict',
  Blocked = 'blocked',
}
