export enum ApiUrl {
  ApiAuthGet = '/auth',
  ApiAuthPost = '/auth',
  ApiCaptchaCode = '/captcha/code',
  ApiAuthLoginOTP = '/auth/create-one-time-password-for-login',
  ApiAuthNewOTP = '/auth/create-one-time-password-for-new-phone',
  ApiClientsGet = '/clients',
  ApiClientsPost = '/clients',
  ApiPatnersGet = '/api/partners',
  ApiPatnersPost = '/partners',
  ApiFilesPatnerGet = '/files/partner',
  ApiGetCurrentPatner = '/partners/current',
  ApiGetPatnerStats = '/statistics/partner?partnerId=',
  ApiPatnerGet = '/api/patners/:id',
  ApiUsersGet = '/api/users',
  ApiUserGet = '/api/users',
  ApiClientProjectsGet = '/projects/headers',
  ApiClientProjectTradeGet = '/projects/project-history',
}
