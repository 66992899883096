import React from 'react';

import checkmark from 'icons/bigcheckmark.svg';

import './SuccessCheckMessage.scss';

export interface ISuccessCheckMessageProps {
  successMessageText: string;
}

export const SuccessCheckMessage: React.FC<ISuccessCheckMessageProps> = ({
  successMessageText,
}) => {
  return (
    <div className='success-message-container'>
      <img src={checkmark} alt="checkmark" />
      <span className='success-message'>{successMessageText}</span>
    </div>
  );
};
