import { useMemo } from 'react';

import { OnboardingLayout } from '../OnboardingLayout';
import { BackOfficeLayout } from '../BackOfficeLayout';

import { LoadingPage } from 'components/pages';

import { useSelectState } from 'hooks/useStore';
import { useAuth } from 'hooks/useAuth';
import { EUserRole } from 'types/userRole.enum';

type AppLayoutProps = {
  children?: React.ReactNode;
};

export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  useAuth();
  const isAuthed = useSelectState((store) => store.auth.isAuthenticated);
  const isAuthWasLoaded = useSelectState((store) => store.auth.isLoaded);
  const userData = useSelectState((store) => store.auth?.user);
  const preUserState = userData?.roles.includes(EUserRole.PreUser);

  const LayoutComponent = useMemo(() => {
    if (!isAuthWasLoaded) return LoadingPage;
    if (isAuthed && !preUserState) return BackOfficeLayout;
    return OnboardingLayout;
  }, [isAuthWasLoaded, isAuthed, preUserState]);

  return <LayoutComponent>{children}</LayoutComponent>;
};
