import axios from "axios";

import { handleAxiosError } from "utils/handleAxiosError";

const apiClient = axios.create({
  baseURL: '/api'
});

apiClient.interceptors.request.use(
  (config) => {
    const bearer = sessionStorage.getItem('bearer') || null;
    config.headers.Authorization = bearer ? `Bearer ${bearer}` : undefined;

    return Promise.resolve(config);
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!axios.isAxiosError(error)) throw error;

    handleAxiosError(error);
  }
);


export { apiClient };