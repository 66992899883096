import React from 'react';
import { Col, Layout, Row } from 'antd';
import { Link } from 'react-router-dom';

import './onboarding.layout.scss';

import { EClientRoute } from 'types/clientRoutes.enum';

import logo from 'icons/biglogo.svg';

import { OnboardingRightPanelPortalId } from 'data/appConstants';

type OnboardingPageTemplateProps = {
  children?: React.ReactNode;
};

export const OnboardingLayout: React.FC<OnboardingPageTemplateProps> = ({ children }) => {
  return (
    <Row className='layout-container'>
      <Col sm={24} md={24} lg={18} className='left-pane'>
        <Layout className='left-pane-container'>
          <Link to={EClientRoute.Root} className='top-logo'>
            <img className='product-logo' src={logo} alt='РусКапитал' />
          </Link>
          {children}
        </Layout>
      </Col>
      <Col xs={0} sm={0} md={0} lg={8} className='right-pane'>
        <div id={OnboardingRightPanelPortalId} className='content' />
      </Col>
    </Row>
  );
};
