import { EClientRoute } from "types/clientRoutes.enum";
import { EUserRole } from "types/userRole.enum";
import { useSelectState } from "./useStore";
import { useLocation, useNavigate } from "react-router";
import { useCallback, useEffect } from "react";

export const useValidateUser = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userData = useSelectState((store) => store.auth.user);
    const validate = useCallback(() => {
        if (userData) {
            if (userData.roles.length === 0) {
                if (!location.pathname.startsWith(EClientRoute.RegisterSent)) {
                    navigate(`${EClientRoute.RegisterSent}/true`);
                }
            }

            if (userData.roles.includes(EUserRole.PreUser)) {
                if (userData.roles.includes(EUserRole.User) || userData.roles.includes(EUserRole.Admin)) {
                    navigate(EClientRoute.Sign);
                }
            }
        }
    }, [location.pathname, navigate, userData]);
    useEffect(() => {
        validate();
    }, [location, validate])
}