import React, { useState } from 'react';
import { Col, Row, Form, Input, Button, notification } from 'antd';
import { Field, newClientFields } from 'data/newClientFields';

import { IAddClientData } from 'types/addClientData.interface';
import { addNewClient } from 'utils/requests';
import { ServerResponseStatus } from 'types/serverResponseStatus.enum';
import { fieldValidator } from 'utils/fieldValidator';

import './backOfficeNewClient.page.scss';
import useApi from 'hooks/useApi';
import { Loader } from 'components/atoms';

export const BackOfficeNewClient: React.FC = () => {
  const [form] = Form.useForm();

  const [clientFields, setClientFields] = useState<IAddClientData>({} as IAddClientData);

  const resetCliendData = () => {
    form.resetFields();
    setClientFields({} as IAddClientData);
  };

  const { isLoading, load: doAddNewClient } = useApi(addNewClient);
  const handleFinish = () => {
    try {
      newClientFields.forEach((f) => {
        const fieldName = f.name as keyof IAddClientData;
        if (fieldValidator[fieldName]) {
          fieldValidator[fieldName](clientFields[fieldName]);
        }
      });
    } catch (error) {
      notification.error({
        message: (error as any)?.message,
      });
    }

    doAddNewClient(clientFields)
      .then(() => {
        resetCliendData();
        notification.success({
          message: 'Клиент отправлен!',
        });
      })
      .catch((error) => {
        if (error === ServerResponseStatus.ValidationError) {
          notification.error({
            message: 'Ошибка валидации! Проверьте правильность заполнения полей: ИНН, телефон, email',
          });
        }
        if (error === ServerResponseStatus.Conflict) {
          notification.error({
            message: 'Данный клиент уже зарегистрирован!',
          });
          resetCliendData();
        }
      });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | undefined, field: Field) => {
    if (!e) return;

    setClientFields({
      ...clientFields,
      [field.name]: e.target.value,
    });
  };

  return (
    <>
      <Form form={form} name='new_client' layout='vertical' onFinish={handleFinish}>
        <Row gutter={18}>
          {newClientFields.map((field) => (
            <Col xs={24} md={12} lg={8} key={field.name.toString()}>
              <Form.Item name={field.name} rules={field.rules} label={field.label}>
                <Input placeholder={field.placeholder} size='large' onChange={(e) => handleInputChange(e, field)} />
              </Form.Item>
            </Col>
          ))}
        </Row>
        <div className='button-container'>
          <Button type='primary' htmlType='submit' disabled={isLoading}>
            Отправить на модерацию
          </Button>
        </div>
        {isLoading && (
          <Row>
            <Loader />
          </Row>
        )}
      </Form>
    </>
  );
};
