import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Col, Dropdown, MenuProps, Row, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import './userMenuItem.scss';

import { EClientRoute } from 'types/clientRoutes.enum';
import { EUserRole } from 'types/userRole.enum';

import circleUser from 'icons/circle-user.svg';
import exit from 'icons/door-open.svg';
import downarrow from 'icons/downarrow.svg';
import { clearUserData } from 'store/authSlice';
import { useSelectState } from 'hooks/useStore';

const RolesLocalizationMap = {
  [EUserRole.Admin]: 'Администратор',
  [EUserRole.Operator]: 'Оператор',
  [EUserRole.PreUser]: 'Пользователь',
  [EUserRole.User]: 'Пользователь',
};

export const UserMenuItem: React.FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelectState((store) => store.auth.user);

  const quitCb = useCallback(() => {
    sessionStorage.clear();
    dispatch(clearUserData());
    navigate(EClientRoute.Root);
  }, [dispatch, navigate]);

  const dropdownItems = useMemo(() => {
    const items: MenuProps['items'] = [];

    items.push({
      key: 'exit',
      label: (
        <Link to='/' onClick={quitCb}>
          <Row gutter={12} align={'middle'}>
            <img src={exit} alt='Выход' />
            <Col>Выход</Col>
          </Row>
        </Link>
      ),
    });

    return items;
  }, [quitCb]);

  return (
    <Row className='user-menu-item'>
      <Col className='user-avatar'>
        <Avatar src={circleUser}></Avatar>
      </Col>
      <Col className='user-info'>
        <Typography>{userData?.name}</Typography>
        {userData && userData?.roles?.length > 0 && <Typography className='muted'>{RolesLocalizationMap[userData?.roles[0]]}</Typography>}
      </Col>
      <Col>
        <Dropdown menu={{ items: dropdownItems }}>
          <img className='user-menu-drop-down-button' src={downarrow} alt='Меню' />
        </Dropdown>
      </Col>
    </Row>
  );
};
