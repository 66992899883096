import { AxiosError } from 'axios';
import { ServerResponseStatus } from 'types/serverResponseStatus.enum';

export const handleAxiosError = (error: AxiosError) => {
  if (!error.response) throw error.message;
  
  const { status, statusText } = error.response;

  switch (status) {
    case 400:
      console.error(`${status}: ${statusText}`);
      throw ServerResponseStatus.ValidationError;
    case 401:
      console.error(`${status}: ${statusText}`);
      throw ServerResponseStatus.AuthorizationError;
    case 403:
      console.error(`${status}: ${statusText}`);
      throw ServerResponseStatus.ForbiddenError;
    case 404:
      console.error(`${status}: ${statusText}`);
      throw ServerResponseStatus.NotFound;
    case 409:
      console.error(`${status}: ${statusText}`);
      throw ServerResponseStatus.Conflict;
    case 429:
      console.error(`${status}: ${statusText}`);
      throw ServerResponseStatus.LimitExceededError;
    case 500:
      console.error(`${status}: ${statusText}`);
      throw ServerResponseStatus.InternalServerError;
    default:
      throw error;
  }
};
